import type {
  Breakpoints,
  Colors,
  Fonts,
  Container,
  NavSize,
} from 'types/theme'

import media from 'styles/media'

export const breakpoints: Breakpoints = {
  xs: 0,
  s: 360,
  xsm: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1760,
}

export const colors: Colors = {
  primary: '#001E3C',
  secondary: '#82AF38',
  dark: '#25292C',
  danger: '#CA7379',
  danger100: '#CD4390',
  red: '#FE4539',
  black: '#000000',
  white: '#FFFFFF',
}

export const fonts: Fonts = {
  size: '10px',
}

export const container: Container = {
  widthXXXL: '1140px',
  width: '900px',

  paddingMD: '2rem',
  padding: '2rem',
}

export const navSize: NavSize = {
  mobile: '70px',
  desktop: '130px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
  container,
  navSize,
  media: media(),
}

export default theme
